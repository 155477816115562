<template>
  <!-- Boards Details View -->
  <MainLayout
    v-scroll="onScroll"
    scroll-toolbar
  >
    <!-- Toolbar -->
    <template #toolbar>
      <div ref="toolbar" class="relative w-full flex items-center justify-between p-3">
        <!-- Left-side actions/info -->
        <div class="flex items-center gap-2 min-w-0" :style="{maxWidth: labelMaxWidth}">
          <button class="p-1.5 text-icon-normal transition-colors hover:text-icon-muted flex-shrink-0"
          @click="navigateBack">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M11.3215 13.3333L8.57742 10.5892C8.25198 10.2638 8.25198 9.73614 8.57742 9.4107L11.3215 6.66663" 
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <div v-if="brandImageLoading" class="p-1">
            <BaseLoadingSpinnerCircle class="text-icon-normal opacity-50" :duration="1" />
          </div>
          <img v-show="!brandImageLoading" :src="brand.avatar" class="w-7 h-7 rounded-md flex-shrink-0" 
          @load="brandImageLoading = false" />
          <BaseText ref="brandLabel" type="label" size="sm" class="text-black flex-shrink truncate cursor-default"
          @mouseenter.native="showBrandTooltip = true" @mouseleave.native="showBrandTooltip = false">
            {{ brand.name }}
          </BaseText>
          <div v-if="brand?.niches?.length || brand.category" class="px-2 py-1.5 rounded-md bg-neutral-25">
            <BaseText type="label" size="sm" class="text-text-muted whitespace-nowrap">
              {{ brand?.niches?.[0] || brand.category }}
            </BaseText>
          </div>
        </div>

        <!-- Discovery Search Bar -->
        <div class="absolute flex top-1/2 transform -translate-y-1/2 pointer-events-none" style="left: 308px; right: 308px; z-index: 40001">
          <div ref="searchBar" class="relative flex-grow mx-auto min-w-0 h-8 pointer-events-auto" style="max-width: 600px">
            <DiscoverySearch 
              :text-search.sync="textSearch"
              :current-brand="brand"
              :qualifier="brand.name"
              keywords-only
              @submit="handleSearchSubmit"
            />
          </div>
        </div>

        <!-- Right-side actions (for now, just ad library) -->
        <button v-if="brand.adLibraryId || brand.linkedinId" class="group ad-library-btn flex items-center gap-1.5 pl-2 py-1.5 pr-3 rounded-md"
        @click="goToAdLibrary">
          <AdLibraryIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
          <BaseText type="label" size="sm" class="text-text-muted">
            Ad Library
          </BaseText>
        </button>

        <!-- Brand label hover tooltip (shows full text if it's truncated) -->
        <transition>
          <div v-if="showBrandTooltip && isLabelTruncated" style="top: 42px; left: 84px; z-index: 40001"
          class="absolute px-2 py-1.5 rounded-md bg-neutral-800 shadow-md cursor-default">
            <BaseText type="body" size="sm" class="text-white">
              {{ brand.name }}
            </BaseText>
          </div>
        </transition>
      </div>
    </template>
    <template #filter>
      <div ref="filterBar" class="border border-border-normal bg-white bg-opacity-90" style="backdrop-filter: blur(6px)">
        <FilterTopBar
          discovery-brands
          no-search
          :text-search="textSearch"
          :selected-formats.sync="selectedFormats"
          :selected-platforms.sync="selectedPlatforms"
          :selected-niches.sync="selectedNiches"
          :selected-languages.sync="selectedLanguages"
          :selected-date-range.sync="selectedDateRange"
          :sort-order.sync="sortOrder"
          :live-status.sync="liveStatus"
          @change="fetchAdvertisements"
        />
      </div>
    </template>

    <template #content>
      <!-- Loading State -->
      <BaseLoadingLogo v-if="loadingAdvertisements" class="mt-24"/>

      <!-- Empty State -->
      <div v-else-if="!advertisements.length" class="flex flex-col items-center mt-20">
        <img src="../../assets/images/empty-single-library.gif" class="border border-gray-400 rounded mx-auto object-cover"
        style="width: 400px; height: 300px">
        <div class="text-lg font-bold my-4">
          {{ textSearch.length ? 'No results found' : 'No ads found' }}
        </div>
      </div>

      <!-- Ads List -->
      <AdvertisementList
        v-else
        :discovery="true"
        :advertisements="advertisements"
        @getMoreAds="() => getMoreAds({})"
        @reload="fetchAdvertisements(false, true)"
      />

      <!-- Create Advertisement Modal -->
      <CreateAdvertisementModal
        v-if="showAdvertisementModal"
        :current-board="board.id"
        @close="showAdvertisementModal = false"
        @save="fetchAdvertisements(false)"
      />

      <!-- Manage Board Modal -->
      <infinite-loading
        v-if="advertisements.length"
        :identifier="infiniteId"
        @infinite="getMoreAds"
      >
        <div slot="spinner">
          <BaseLoadingLogo :margin="2" />
        </div>
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import ForeplayAPI from '@/api/foreplayServer'
import FirebaseAPI from '@/api/firebase'

// Components
import AdvertisementList from '../../components/advertisements/AdvertisementList'
import AdvertisementSavingList from '../../components/advertisements/AdvertisementSavingList'
import CreateAdvertisementModal from '../../components/advertisements/AdvertisementCreateModal'
import MainLayout from '../../layouts/MainLayout'
import InfiniteLoading from 'vue-infinite-loading'
import FilterTopBar from '../../components/search/FilterTopBar.vue'
import DiscoverySearch from '../../components/discovery/DiscoverySearch.vue'

// Icons
import LinkedInIcon from '../../components/globals/Icons/FilterIcons/LinkedInIcon.vue'
import FacebookIcon from '../../components/globals/Icons/FilterIcons/FacebookIcon.vue'
import AdLibraryIcon from '../../components/globals/Icons/AdLibraryIcon.vue'

export default {
  name: 'DiscoveryBrandView',
  components: {
    AdvertisementList,
    AdvertisementSavingList,
    CreateAdvertisementModal,
    MainLayout,
    FacebookIcon,
    LinkedInIcon,
    AdLibraryIcon,
    InfiniteLoading,
    FilterTopBar,
    DiscoverySearch
  },
  data () {
    return {
      // Data States
      brand: {},
      advertisements: [],
      selectedBoard: {},
      infiniteId: +new Date(),
      lastDocId: null,
      shouldUpdateQuery: new Date().toString(),

      // Search + Filters
      textSearch: '',
      filtersApplied: false,
      selectedFormats: [],
      selectedNiches: [],
      selectedLanguages: [],
      liveStatus: [],
      sortOrder: [],
      selectedPlatforms: [],
      selectedDateRange: {
        start: null,
        end: null
      },
      favoriteFilter: false,

      // UI States
      loadingAdvertisements: true,
      showManageBoardModal: false,
      showAdvertisementModal: false,
      infiniteLoaderState: null,
      brandImageLoading: true,
      labelMaxWidth: '308px',
      showBrandTooltip: false,
      isLabelTruncated: false
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', ['getUserEmail', 'getUserName', 'getTeam', 'getUser']),
    ...mapGetters('BoardsModule', ['getBoards']),
    ...mapGetters('RouterModule', ['getLastRoute']),
    // Get Advertisements
    getAdvertisements () {
      return this.advertisements
    }
  },
  created () {
    if (this.$route.query.search) {
      this.textSearch = this.$route.query.search
    }
  },
  async mounted () {
    this.$refs.filterBar.style.position = 'sticky'
    this.$refs.filterBar.style.top = '16px'
    this.$refs.filterBar.style.zIndex = '40000'

    this.sortOrder = this.textSearch.length
        ? [{ name: 'Most Relevant', value: 'relevance' }]
        : [{ name: 'Newest', value: 'desc' }]

    // Store the current brand ad count for future comparison in dashboard
    if (this.getUser?.favoritedBrands?.includes(this.brand?.id)) {
      await this.addLastVisited()
    }
    await this.fetchAdvertisements()
    
    this.$nextTick(() => {
      this.computeLabelMaxWidth()
      window.addEventListener('resize', this.computeLabelMaxWidth)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.computeLabelMaxWidth)
  },
  methods: {
    async addLastVisited () {
      // Setup
      const db = firebase.firestore()

      db.collection('brands-visited')
        .doc(`${this.getUser.user_id}-${this.brand.id}`)
        .set({
          currentCount: this.brand.adsSaved
        })
    },
    handleSearchSubmit () {
      this.updateRouteQuery()
      this.sortOrder = this.textSearch.length
        ? [{ name: 'Most Relevant', value: 'relevance' }]
        : [{ name: 'Newest', value: 'desc' }]
      this.fetchAdvertisements()
    },
    updateRouteQuery () {
      if (!this.textSearch.length) {
        window.history.replaceState({}, '', this.$route.path)
        return
      }
      // Update the route's search query parameter with the current textSearch value
      const params = new URLSearchParams(window.location.search)
      params.set('search', this.textSearch)
      const newUrl = `${this.$route.path}?${params.toString()}`
      window.history.replaceState({}, '', newUrl)
    },
    async fetchAdvertisements (load = true, refresh = false) {
      this.loadingAdvertisements = load
      this.advertisements = []

      if (refresh) {
        this.lastDocId = null
      }
      this.brand = await FirebaseAPI.Brands.get(this.$route.params.id)

      const ranBetweenStart = +new Date(this.selectedDateRange?.start) || null
      const ranBetweenEnd = +new Date(this.selectedDateRange?.end) || null
      try {
        const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(
          null,
          {
            ranBetweenStart,
            ranBetweenEnd,
            textSearch: this.textSearch,
            sort: this.sortOrder[0].value,
            orFilters: {
              formats: this.selectedFormats,
              niches: this.selectedNiches,
              publisher_platform: this.selectedPlatforms,
              liveStatus: this.liveStatus,
              languages: this.selectedLanguages,
              brands: [{ brandId: this.$route.params.id }]
            }
          },
          true // Include spyder ads
        )

        this.lastDocId = nextPage
        this.advertisements = results
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    },
    async getMoreAds ($state) {
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const ranBetweenStart = +new Date(this.selectedDateRange.start) || null
      const ranBetweenEnd = +new Date(this.selectedDateRange.end) || null
      const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(
        this.lastDocId,
        {
          ranBetweenStart,
          ranBetweenEnd,
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
            formats: this.selectedFormats,
            niches: this.selectedNiches,
            publisher_platform: this.selectedPlatforms,
            liveStatus: this.liveStatus,
            languages: this.selectedLanguages,
            brands: [{ brandId: this.$route.params.id }]
          }
        },
        true // Include spyder ads
      )

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    onScroll (e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5

      this.$refs.filterBar.style.marginLeft = `${marginValue}px`
      this.$refs.filterBar.style.marginRight = `${marginValue}px`
      this.$refs.filterBar.style.marginTop = `${marginValue}px`
      this.$refs.filterBar.style.borderRadius = `${rounding}px`

      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop) {
        this.$refs.filterBar.style.boxShadow = `rgba(0, 0, 0, 0.08) 0px ${shadowSection(
          15
        )}px ${shadowSection(55)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
          7
        )}px ${shadowSection(
          10
        )}px, rgba(0, 0, 0, 0.08) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px ${shadowSection(
          7
        )}px ${shadowSection(13)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
          3
        )}px ${shadowSection(5)}px`
        // this.$refs.filterBar.style.boxShadow = `rgba(58, 111, 251, 0.4) -${shadowSection(5)}px ${shadowSection(5)}px, rgba(58, 111, 251, 0.3) -${shadowSection(10)}px ${shadowSection(10)}px, rgba(58, 111, 251, 0.2) -${shadowSection(15)}px ${shadowSection(15)}px, rgba(58, 111, 251, 0.1) -${shadowSection(20)}px ${shadowSection(20)}px, rgba(58, 111, 251, 0.05) -${shadowSection(25)}px ${shadowSection(25)}px`
      } else {
        this.$refs.filterBar.style.boxShadow = null
      }
    },
    goToAdLibrary () {
      if (!this.brand.adLibraryId && !this.brand.linkedinId) return
      const url = this.brand.adLibraryId 
        ? `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${this.brand.adLibraryId}`
        : `https://www.linkedin.com/company/${this.brand.linkedinId}/posts/?feedView=ads`
      window.open(url, '_blank')      
    },
    navigateBack () {
      // If there is a previous history entry, go back
      if (this.getLastRoute) {
        this.$router.go(-1)
      } else {
        // Otherwise, go to the home page
        this.$router.push({ name: 'DiscoveryBrandsView' })
      }
    },
    computeLabelMaxWidth () {
      const toolbarLeft = this.$refs.toolbar.getBoundingClientRect().left
      const searchLeft = this.$refs.searchBar.getBoundingClientRect().left
      const maxWidth = searchLeft - toolbarLeft - 16
      this.labelMaxWidth = `${maxWidth}px`

      // Determine if the label is truncated
      const brandLabel = this.$refs.brandLabel.$el
      this.isLabelTruncated = brandLabel.scrollWidth > brandLabel.clientWidth
    }
  }
}
</script>

<style scoped>
.ad-library-btn {
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.12), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
}
.ad-library-btn:hover {
  background-color: #F6F8FA; /* neutral-25 */
  box-shadow: none;
}

/* ========= Vue <transition> classes ========= */
.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0
}
.v-enter-to, .v-leave-from {
  opacity: 1
}
</style>
